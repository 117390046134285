import React from 'react';

import ErrorOverlay from '../components/01_atoms/ErrorOverlay';
import '../styles/styles.scss';

const ErrorPage = (props) => (
  <div className="error-page">
    <ErrorOverlay {...props} />
  </div>
);

// Opt-out of Automatic Static Optimization.
// See https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
ErrorPage.getInitialProps = async () => ({});

export default ErrorPage;
